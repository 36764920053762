<template>
  <b-card no-body>
    <b-card-header>
      <h3>{{ $t("company.sub_companies") }}</h3>
    </b-card-header>
    <b-card-body>
      <b-overlay
        :show="!tableReady"
        variant="dark"
        opacity="0.85"
        blur="2px"
        rounded="sm"
      >
        <div v-if="tableData.length">
          <table id="SubCompanies" class="dataTable dt-responsive table w-100">
            <thead>
              <tr>
                <th>#</th>
                <th>{{ $t("company.list.code") }}</th>
                <th>{{ $t("company.list.name") }}</th>
                <th>{{ $t("company.list.tax_or_id") }}</th>
                <th>{{ $t("company.list.type") }}</th>
                <th>{{ $t("dashboard.state") }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in tableData" :key="item.company_id">
                <td>{{ item.company_id }}</td>
                <td>{{ item.company_code }}</td>
                <td>{{ item.company_name }}</td>
                <td>
                  {{ item.tax_number ? item.tax_number : item.identity_number }}
                </td>
                <td>
                  {{
                    item.tax_number
                      ? $t("company_crud.1.corporate")
                      : $t("company_crud.1.individual")
                  }}
                </td>
                <td class="text-center">
                  <b-badge
                    :variant="
                      item.is_passive ? 'light-danger' : 'light-success'
                    "
                  >
                    {{
                      item.is_passive
                        ? $t("dashboard.passive")
                        : $t("dashboard.active")
                    }}
                  </b-badge>
                </td>
                <td class="text-center">
                  <span>
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                      right
                    >
                      <template v-slot:button-content>
                        <i class="far fa-ellipsis-v processes-icon"></i>
                      </template>
                      <b-link
                        :to="{
                          name: '/Company/Display',
                          params: { id: item.company_id },
                        }"
                        target="_blank"
                        class="dropdown-item"
                      >
                        <i class="fal fa-info-circle font-small-4 mr-50" />
                        {{ $t("Display") }}
                      </b-link>
                      <b-link
                        :to="{
                          name: '/Company/Edit',
                          params: { id: item.company_id },
                        }"
                        target="_blank"
                        class="dropdown-item"
                      >
                        <i class="fal fa-edit font-small-4 mr-50"></i>
                        {{ $t("Edit") }}
                      </b-link>
                    </b-dropdown>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <b-alert variant="danger" :show="tableReady" v-else>
          <h4 class="alert-heading d-flex justify-content-between">
            <div>
              {{ $t("company.sub_companies") }}
            </div>
            <b-button variant="none" size="sm" @click="getTableData()">
              <i class="fal fa-sync-alt text-danger" />
            </b-button>
          </h4>
          <div class="alert-body">
            <span>
              {{ $t("company.sub_companies_not_found") }}
            </span>
          </div>
        </b-alert>
      </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
import $ from "jquery";
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BLink,
  BOverlay,
  BBadge,
  BAlert,
  BButton,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import { GET_ALL_SUB_COMPANY } from "@/store/services/company-service";
export default {
  name: "company-sub-companies-list",
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BLink,
    BOverlay,
    BBadge,
    BAlert,
    BButton,
  },
  data() {
    return {
      table: null,
      tableData: [],
      tableReady: false,
    };
  },
  computed: {
    ...mapGetters(["getLocale"]),
  },
  created() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.tableReady = false;
      this.$store
        .dispatch(GET_ALL_SUB_COMPANY, this.$route.params.id)
        .then((response) => {
          this.tableData = response;
          this.$nextTick(() => {
            this.createTable();
          });
        });
    },
    createTable() {
      this.tableReady = false;
      this.table = $("#SubCompanies").DataTable({
        columnDefs: [{ orderable: false, targets: [4] }],
        scrollX: true,
        scrollY: "1000px",
        scrollCollapse: true,
        buttons: [
          {
            extend: "colvis",
            text: this.$t("dataTable.show_hide"),
          },
          {
            extend: "pdf",
          },
          {
            extend: "excel",
            title: "Alt Bayiler",
            filename: "Alt Bayiler",
          },
        ],
        language: {
          url:
            this.getLocale == "tr-TR"
              ? "//cdn.datatables.net/plug-ins/1.11.1/i18n/tr.json"
              : "//cdn.datatables.net/plug-ins/1.11.1/i18n/en-gb.json",
        },
      });
      this.tableReady = true;
    },
  },
  watch: {
    getLocale() {
      Promise.all([this.table.destroy()]).then(this.createTable);
    },
  },
};
</script>