<template>
  <b-card
    class="profile-header mb-2"
    :img-src="require('@/assets/images/banners/company_banner.png')"
    img-top
    alt="cover photo"
    body-class="p-0"
  >
    <!-- profile picture -->
    <div class="position-relative">
      <div class="profile-img-container d-flex align-items-center">
        <div class="profile-img">
          <b-img
            :src="
              companyData.image_base_64
                ? companyData.image_base_64
                : require('@/assets/images/avatars/company_default.png')
            "
            rounded
            fluid
            alt="profile photo"
            class="company-photo"
          />
        </div>
        <!-- profile title -->
        <div class="profile-title ml-3">
          <h2 class="text-white">{{ companyData.company_trade_name }}</h2>
          <p class="text-white">
            {{ companyData.master_company ? "Ana Bayi" : "Bayi" }}
          </p>
        </div>
        <!--/ profile title -->
      </div>
    </div>
    <!--/ profile picture -->

    <!-- profile navbar -->
    <div class="profile-header-nav">
      <b-navbar toggleable="md" type="light">
        <!-- toggle button -->
        <b-navbar-toggle class="ml-auto" target="nav-text-collapse">
          <feather-icon icon="AlignJustifyIcon" size="21" />
        </b-navbar-toggle>
        <!--/ toggle button -->

        <!-- collapse -->
        <b-collapse id="nav-text-collapse" is-nav>
          <b-tabs pills class="profile-tabs mt-1 mt-md-0" nav-class="mb-0">
            <template #tabs-start>
              <b-nav-item
                role="presentation"
                :active="companyDisplayTab === 1"
                @click="changeTab(1)"
                class="font-weight-bold"
              >
                <span class="d-none d-md-block">
                  {{ $t("company.general") }}
                </span>
                <feather-icon icon="RssIcon" class="d-block d-md-none" />
              </b-nav-item>
              <b-nav-item
                role="presentation"
                :active="companyDisplayTab === 2"
                @click="changeTab(2)"
                class="font-weight-bold"
              >
                <span class="d-none d-md-block">{{ $t("company.risk") }}</span>
                <feather-icon icon="InfoIcon" class="d-block d-md-none" />
              </b-nav-item>
              <b-nav-item
                role="presentation"
                :active="companyDisplayTab === 3"
                @click="changeTab(3)"
                class="font-weight-bold"
              >
                <span class="d-none d-md-block">{{ $t("company.users") }}</span>
                <feather-icon icon="UsersIcon" class="d-block d-md-none" />
              </b-nav-item>
              <b-nav-item
                role="presentation"
                :active="companyDisplayTab === 4"
                @click="changeTab(4)"
                class="font-weight-bold"
              >
                <span class="d-none d-md-block">{{
                  $t("company.sub_companies")
                }}</span>
                <feather-icon icon="LayersIcon" class="d-block d-md-none" />
              </b-nav-item>
              <b-nav-item
                role="presentation"
                :active="companyDisplayTab === 5"
                @click="changeTab(5)"
                class="font-weight-bold"
              >
                <span class="d-none d-md-block">
                  {{ $t("company.address") }}
                </span>
                <feather-icon icon="BookIcon" class="d-block d-md-none" />
              </b-nav-item>
              <b-nav-item
                role="presentation"
                :active="companyDisplayTab === 6"
                @click="changeTab(6)"
                class="font-weight-bold"
              >
                <span class="d-none d-md-block">
                  {{ $t("company.price_list") }}
                </span>
                <feather-icon icon="DollarSignIcon" class="d-block d-md-none" />
              </b-nav-item>
              <b-nav-item
                role="presentation"
                :active="companyDisplayTab === 7"
                @click="changeTab(7)"
                class="font-weight-bold"
              >
                <span class="d-none d-md-block">
                  {{ $t("company.current_extract") }}
                </span>
                <feather-icon icon="FileTextIcon" class="d-block d-md-none" />
              </b-nav-item>
            </template>

            <!-- edit buttons -->
            <template #tabs-end>
              <b-button variant="primary" class="ml-auto" @click="goEdit()">
                <feather-icon icon="EditIcon" class="d-block d-md-none" />
                <span class="font-weight-bold d-none d-md-block">
                  {{ $t("Edit") }}
                </span>
              </b-button>
            </template>
            <!-- edit buttons -->
          </b-tabs>
        </b-collapse>
        <!--/ collapse -->
      </b-navbar>
    </div>
    <!--/ profile navbar -->
  </b-card>
</template>

<script>
import {
  BCard,
  BImg,
  BNavbar,
  BNavbarToggle,
  BCollapse,
  BTabs,
  BNavItem,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import { SET_COMPANY_DISPLAY_TAB } from "@/store/services/company-service";

export default {
  components: {
    BCard,
    BTabs,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    companyData: {
      required: true,
    },
  },
  computed: {
    ...mapGetters(["companyDisplayTab"]),
  },
  methods: {
    changeTab(val) {
      this.$store.commit(SET_COMPANY_DISPLAY_TAB, val);
    },
    goEdit() {
      this.$router.push({
        name: "/Company/Edit",
        params: { id: this.companyData.company_id },
      });
    },
  },
};
</script>

<style lang="scss">
.card-img-top {
  height: 300px;
  object-fit: cover;
  overflow: hidden;
}
.profile-img {
  border-radius: 50% !important;
}
</style>
