<template>
  <b-card no-body>
    <b-card-header>
      <h3>{{ $t('company.address') }}</h3>
    </b-card-header>
    <b-card-body class="px-3">
      <b-card no-body>
        <b-card-header>
          <b-card-title>{{ $t('company.invoice_address') }}</b-card-title>
        </b-card-header>
        <b-card-body>
          <div class="shipping-address-container" v-show="invoiceAddressList.length">
            <b-card no-body class="shipping-address-card" v-for="item in invoiceAddressList" :key="item.company_address_id">
              <h3>{{ item.address_alias }}</h3>
              <span>{{ item.address_line1 }}</span>
              <span>
                {{ `${item.post_code} - ${item.town.town_name} / ${item.city.city_name} ${item.country.country_name}` }}
              </span>
              <span>
                {{ phoneFormat(item.phone) }}
              </span>
              <span>
                {{ item.e_mail }}
              </span>
            </b-card>
          </div>
          <h3 v-show="invoiceAddressList.length === 0">
            {{ $t('company.address_not_found') }}
          </h3>
        </b-card-body>
      </b-card>

      <b-card no-body>
        <b-card-header class="flex-column align-items-start">
          <b-card-title>{{ $t('company.shipping_address_list') }}</b-card-title>
        </b-card-header>
        <b-card-body>
          <b-card no-body text-variant="white" class="invoice-address-card bg-gradient-primary" v-for="item in shippingAddressList" :key="item.company_address_id">
            <h3 class="text-white">{{ item.address_alias }}</h3>
            <span> {{ subCompanyTaxOffice }} </span>
            <span>{{ item.address_line1 }}</span>
            <span v-if="item.address_line2 !== ''">
              {{ item.address_line2 }}
            </span>
            <span v-if="item.address_line3 !== ''">
              {{ item.address_line3 }}
            </span>
            <span>
              {{ `${item.post_code} - ${item.town.town_name} / ${item.city.city_name} ${item.country.country_name}` }}
            </span>
            <span>
              {{ phoneFormat(item.phone) }}
            </span>
            <span>
              {{ item.e_mail }}
            </span>
          </b-card>
          <h3 v-show="shippingAddressList.length === 0">
            {{ $t('company.address_not_found') }}
          </h3>
        </b-card-body>
      </b-card>
    </b-card-body>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, BCardBody } from 'bootstrap-vue'

import { mapGetters } from 'vuex'

export default {
  name: 'company-addresses',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody
  },
  props: {
    companyData: { required: true }
  },
  computed: {
    ...mapGetters(['getLocale']),
    shippingAddressList() {
      if ('company_address' in this.companyData) {
        let address = this.companyData.company_address
        return address.filter(x => x.address_type_id !== 1)
      } else {
        return []
      }
    },
    invoiceAddressList() {
      if ('company_address' in this.companyData) {
        let address = this.companyData.company_address
        return address.filter(x => x.address_type_id === 1)
      } else {
        return []
      }
    }
  },
  methods: {
    phoneFormat(phone) {
      return `+90 ${phone.slice(0, 3)} ${phone.slice(3, 6)} 
      ${phone.slice(6, 8)} ${phone.slice(8, 10)}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/core/colors/palette-variables.scss';
.invoice-address-card {
  padding: 20px;
  .update-button {
    display: flex;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
.shipping-address-card {
  height: 200px;
  padding: 20px;
  background-color: rgb(242, 244, 244);
  box-shadow: rgb(25 42 70 / 13%) 2px 8px 10px 0px !important;
  .update-button {
    display: flex;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.dark-layout {
  .shipping-address-card {
    background-color: rgb(22, 29, 49);
  }
}

.check-icon {
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
}

.shipping-address-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

@include media-breakpoint-up(md) {
  .shipping-address-container {
    grid-template-columns: 1fr 1fr;
  }
}

@include media-breakpoint-up(lg) {
  .shipping-address-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@include media-breakpoint-up(xl) {
  .shipping-address-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
</style>
