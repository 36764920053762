<template>
  <b-card no-body>
    <b-card-header>
      <h3>{{ $t("company.current_extract") }}</h3>
    </b-card-header>
    <b-card-body>
      <b-overlay
        :show="!tableReady"
        variant="dark"
        opacity="0.85"
        blur="2px"
        rounded="sm"
      >
        <div v-if="tableData.length">
          <table id="TransactionList" class="dataTable table">
            <thead>
              <tr>
                <th>{{ $t("company.transaction_list.DOC_DATE") }}</th>
                <th>{{ $t("company.transaction_list.DUE_DATE") }}</th>
                <th>{{ $t("company.transaction_list.TRA_NAME") }}</th>
                <th>{{ $t("company.transaction_list.SOURCE_APP") }}</th>
                <th>{{ $t("company.transaction_list.SC") }}</th>
                <th class="text-right">
                  {{ $t("company.transaction_list.DEBIT") }}
                </th>
                <th class="text-right">
                  {{ $t("company.transaction_list.CREDIT") }}
                </th>
                <th class="text-right">
                  {{ $t("company.transaction_list.RUNNING_TOTAL") }}
                </th>
                <th class="text-center">
                  {{ $t("company.transaction_list.STATE") }}
                </th>
                <th>{{ $t("company.transaction_list.CUR_CODE") }}</th>
                <th class="text-right">
                  {{ $t("company.transaction_list.CUR_RATE") }}
                </th>
                <th class="text-right">
                  {{ $t("company.transaction_list.DEBIT_CUR") }}
                </th>
                <th class="text-right">
                  {{ $t("company.transaction_list.CREDIT_CUR") }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in tableData" :key="item.company_id">
                <td>
                  {{ showDate(item.DOC_DATE) }}
                </td>
                <td>{{ showDate(item.DUE_DATE) }}</td>
                <td>{{ item.TRA_NAME }}</td>
                <td>{{ item.SOURCE_APP }}</td>
                <td>{{ item.SC }}</td>
                <td class="text-right text-nowrap">
                  {{ priceTL(item.DEBIT) }}
                </td>
                <td class="text-right text-nowrap">
                  {{ priceTL(item.CREDIT) }}
                </td>
                <td
                  class="text-right text-white text-nowrap font-weight-bold"
                  :class="bgVariant(item.STATE)"
                >
                  {{ priceTL(item.RUNNING_TOTAL) }}
                </td>
                <td class="text-center">
                  <b-badge :variant="stateVariant(item.STATE)">
                    {{ $t("company.transaction_list.state_" + item.STATE) }}
                  </b-badge>
                </td>
                <td>{{ item.CUR_CODE }}</td>
                <td class="text-right text-nowrap">
                  {{ priceTL(item.CUR_RATE) }}
                </td>
                <td class="text-right text-nowrap">
                  {{ priceDebit(item) }}
                </td>
                <td class="text-right text-nowrap">
                  {{ priceCredit(item) }}
                </td>
                <td class="text-center"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <b-alert variant="danger" :show="tableReady" v-else>
          <h4 class="alert-heading d-flex justify-content-between">
            <div>
              {{ $t("Company Transaction") }}
            </div>
            <b-button variant="none" size="sm" @click="getTableData()">
              <i class="fal fa-sync-alt text-danger" />
            </b-button>
          </h4>
          <div class="alert-body">
            <span>
              {{ $t("notifications.get_company_transaction_not_found") }}
            </span>
          </div>
        </b-alert>
      </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
import $ from "jquery";
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BLink,
  BOverlay,
  BBadge,
  BAlert,
  BButton,
} from "bootstrap-vue";
import { GET_COMPANY_TRANSACTION_LIST } from "@/store/services/company-service";
import { mapGetters } from "vuex";
import moment from "moment";
import { priceFormat } from "@/assets/js/functions/currency-functions";
export default {
  name: "company-transaction-list",
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BLink,
    BOverlay,
    BBadge,
    BAlert,
    BButton,
  },
  data() {
    return {
      table: null,
      tableData: [],
      tableReady: false,
    };
  },
  computed: {
    ...mapGetters(["getLocale"]),
  },
  created() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.tableReady = false;
      this.$store
        .dispatch(GET_COMPANY_TRANSACTION_LIST, this.$route.params.id)
        .then((response) => {
          this.tableData = response;
          this.$nextTick(() => {
            this.createTable();
          });
        });
    },
    createTable() {
      this.table = $("#TransactionList").DataTable({
        columnDefs: [
          {
            orderable: false,
            targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
          },
        ],
        scrollX: true,
        scrollY: "1000px",
        scrollCollapse: true,
        buttons: [
          {
            extend: "colvis",
            text: this.$t("dataTable.show_hide"),
          },
          {
            extend: "pdf",
            title: `${this.$t("company.current_extract")}
            ${this.$t("Company Transaction")}
            ${moment().format("DD.MM.YYYY")}`,
            filename: `${this.$t("company.current_extract")}
            ${this.$t("Company Transaction")} 
            ${moment().format("DD.MM.YYYY")}`,
            orientation: "landscape",
            pageSize: "LEGAL",
          },
          {
            extend: "excel",
            title: `${this.$t("company.current_extract")}
            ${this.$t("Company Transaction")}
            ${moment().format("DD.MM.YYYY")}`,
            filename: `${this.$t("company.current_extract")}
            ${this.$t("Company Transaction")}
            ${moment().format("DD.MM.YYYY")}`,
          },
        ],
        language: {
          url:
            this.getLocale == "tr-TR"
              ? "//cdn.datatables.net/plug-ins/1.11.1/i18n/tr.json"
              : "//cdn.datatables.net/plug-ins/1.11.1/i18n/en-gb.json",
        },
      });
      this.tableReady = true;
    },
    showDate(date) {
      return new Intl.DateTimeFormat(this.getLocale, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }).format(new Date(date));
    },
    priceTL(amount) {
      return priceFormat(Math.abs(amount), this.getLocale, "TRY");
    },
    itemCur(item) {
      let temp = {};
      temp[item.CUR_CODE] = item.CUR_RATE;
      return temp;
    },
    priceDebit(item) {
      return priceFormat(item.DEBIT_CUR, this.getLocale, item.CUR_CODE);
    },
    priceCredit(item) {
      return priceFormat(item.CREDIT_CUR, this.getLocale, item.CUR_CODE);
    },
    stateVariant(state) {
      switch (state) {
        case 0:
          return "light-danger";
        case 1:
          return "light-success";
        case 2:
          return "light-secondary";
      }
    },
    bgVariant(state) {
      switch (state) {
        case 0:
          return "bg-light-danger";
        case 1:
          return "bg-light-success";
        case 2:
          return "bg-light-secondary";
      }
    },
  },
  watch: {
    getLocale() {
      Promise.all([this.table.destroy()]).then(this.createTable);
    },
  },
};
</script>