<template>
  <div id="user-profile">
    <b-overlay :show="!ready" variant="dark" opacity="0.85" blur="2px" rounded="sm">
      <company-header :company-data="companyData" />
      <div v-if="ready">
        <company-general :company-data="companyData" v-if="companyDisplayTab === 1" />
        <company-users v-if="companyDisplayTab === 3" />
        <company-sub-companies v-if="companyDisplayTab === 4" />
        <company-address :company-data="companyData" v-if="companyDisplayTab === 5" />
        <company-prices :company-data="companyData" v-if="companyDisplayTab === 6" />
        <company-transaction-list v-if="companyDisplayTab === 7" />
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { BRow, BCol, BOverlay } from 'bootstrap-vue'

import CompanyHeader from './CompanyHeader.vue'
import CompanyGeneral from './General.vue'
import CompanyUsers from './Users.vue'
import CompanySubCompanies from './SubCompanies.vue'
import CompanyAddress from './Address.vue'
import CompanyPrices from './Prices.vue'
import CompanyTransactionList from './Transaction.vue'

import { GET_COMPANY } from '@/store/services/company-service'
import { mapGetters } from 'vuex'

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    BOverlay,

    CompanyHeader,
    CompanyGeneral,
    CompanyUsers,
    CompanySubCompanies,
    CompanyAddress,
    CompanyPrices,
    CompanyTransactionList
  },
  data() {
    return {
      companyData: {},
      ready: false,
      activeTab: 1
    }
  },
  created() {
    this.$store.dispatch(GET_COMPANY, { resolve: true, id: this.$route.params.id }).then(response => {
      this.companyData = response
      this.ready = true
    })
  },
  computed: {
    ...mapGetters(['companyDisplayTab'])
  }
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-profile.scss';
</style>
