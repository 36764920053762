<template>
  <b-card>
    <b-row>
      <b-col cols="12" md="6">
        <b-row>
          <b-col cols="12">
            <p>{{ `Bayi Adı: ${companyData.company_name}` }}</p>
          </b-col>
          <b-col cols="12">
            <p>{{ `Bayi Kısa Adı: ${companyData.company_trade_name}` }}</p>
          </b-col>
          <b-col cols="12">
            <p>{{ `Bayi Kodu: ${companyData.company_code}` }}</p>
          </b-col>
          <b-col cols="12">
            <p>
              {{ `Vergi Dairesi: ${companyData.tax_office.tax_office_name}` }}
            </p>
          </b-col>
          <b-col
            cols="12"
            v-if="
              companyData.tax_number !== '' && companyData.tax_number !== null
            "
          >
            <p>{{ `Vergi No: ${companyData.tax_number}` }}</p>
          </b-col>
          <b-col
            cols="12"
            v-if="
              companyData.identity_number !== '' &&
              companyData.identity_number !== null
            "
          >
            <p>{{ `Kimlik No: ${companyData.identity_number}` }}</p>
          </b-col>
          <b-col cols="12">
            <p>
              {{
                `Bayi Durumu: ${!companyData.is_passive ? "Aktif" : "Pasif"}`
              }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  props: {
    companyData: { required: true },
  },
};
/* eslint-disable global-require */
</script>
