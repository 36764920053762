<template>
  <b-card no-body>
    <b-card-header class="d-flex justify-content-between">
      <h3>{{ $t('company.users') }}</h3>
      <b-button variant="success" @click="openCreateUser()">
        {{ $t('company.new_user') }}
        <feather-icon icon="PlusIcon" />
      </b-button>
    </b-card-header>
    <b-card-body>
      <table id="companyUsers" class="dataTable table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('user.name') }}</th>
            <th>{{ $t('user.surname') }}</th>
            <th>{{ $t('Mail') }}</th>
            <th>{{ $t('Phone') }}</th>
            <th>{{ $t('State') }}</th>
            <th>{{ $t('UserType') }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in tableData" :key="item.user_id">
            <td class="text-rigth">{{ item.user_id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.surname }}</td>
            <td>{{ item.email }}</td>
            <td>{{ phoneFormat(item.phone) }}</td>
            <td class="text-center">
              <b-badge :variant="item.is_passive ? 'light-danger' : 'light-success'">
                {{ item.is_passive ? $t('Passive') : $t('Active') }}
              </b-badge>
            </td>
            <td class="text-center">
              <b-badge :variant="item.sys_admin ? 'light-success' : 'light-warning'">
                {{ item.sys_admin ? $t('Admin') : $t('User') }}
              </b-badge>
            </td>
            <td class="text-center">
              <span>
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret right>
                  <template v-slot:button-content>
                    <i class="far fa-ellipsis-v processes-icon"></i>
                  </template>
                  <b-link
                    v-if="$can('manage', '/User/Edit')"
                    :to="{
                      name: '/User/Detail',
                      params: { id: item.user_id }
                    }"
                    target="_blank"
                    class="dropdown-item"
                  >
                    <i class="fal fa-info-circle font-small-4 mr-50"></i>
                    {{ $t('Edit') }}
                  </b-link>
                </b-dropdown>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card-body>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BCardHeader, BCardBody, BBadge, BDropdown, BLink, BButton } from 'bootstrap-vue'
import '@/assets/js/functions/datatables.settings'
import $ from 'jquery'
import { GET_ALL_COMPANY_USERS } from '@/store/services/company-service'
import { mapGetters } from 'vuex'
/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BDropdown,
    BBadge,
    BLink,
    BButton
  },
  data() {
    return {
      tableData: [],
      table: null,
      tableReady: false
    }
  },
  computed: {
    ...mapGetters(['getLocale'])
  },
  created() {
    this.$store.dispatch(GET_ALL_COMPANY_USERS, this.$route.params.id).then(response => {
      this.tableData = response
      this.$nextTick(() => {
        this.createTable()
      })
    })
  },
  methods: {
    createTable() {
      this.tableReady = false
      this.table = $('#companyUsers').DataTable({
        //columnDefs: [{ orderable: false, targets: [4] }],
        scrollX: true,
        scrollY: '1000px',
        scrollCollapse: true,
        buttons: [
          {
            extend: 'colvis',
            text: this.$t('dataTable.show_hide')
          },
          {
            extend: 'pdf'
          },
          {
            extend: 'excel',
            title: 'Bayi Kullanıcıları',
            filename: 'Bayi Kullanıcıları'
          }
        ],
        language: {
          url: this.getLocale == 'tr-TR' ? '//cdn.datatables.net/plug-ins/1.11.1/i18n/tr.json' : '//cdn.datatables.net/plug-ins/1.11.1/i18n/en-gb.json'
        }
      })
      this.tableReady = true
    },
    openCreateUser() {
      let routeData = this.$router.resolve({
        name: '/Company/CreateUser',
        params: { id: this.$route.params.id }
      })
      window.open(routeData.href, '_blank')
    },
    reDraw() {
      Promise.all([this.table.destroy()]).then(this.createTable)
    },
    phoneFormat(phone) {
      return `+90 ${phone.slice(0, 3)} ${phone.slice(3, 6)} 
      ${phone.slice(6, 8)} ${phone.slice(8, 10)}`
    }
  },
  watch: {
    getLocale() {
      this.reDraw()
    }
  }
}
</script>
