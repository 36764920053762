<template>
  <b-card no-body>
    <b-overlay
      :show="!tableReady"
      variant="dark"
      opacity="0.85"
      blur="2px"
      rounded="sm"
    >
      <b-card-header>
        <h3>{{ $t("company.price_list") }}</h3>
      </b-card-header>
      <b-card-body v-show="tableReady">
        <table id="companyPrices" class="dataTable table">
          <thead>
            <tr>
              <th>{{ $t("shop.product_code_tooltip") }}</th>
              <th>{{ $t("shop.product_name_tooltip") }}</th>
              <th>{{ $t("transaction.p_unit") }}</th>
              <th class="text-right">{{ $t("transaction.p_price") }}</th>
              <th class="text-right">{{ $t("transaction.p_price_try") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tableData" :key="item.product_code">
              <td>{{ item.product_code }}</td>
              <td>
                <b-link
                  :to="{
                    name: '/Shop/Product',
                    params: { id: item.product_id },
                  }"
                  :id="'product-' + item.product_id"
                  target="_blank"
                >
                  {{ item.product_name }}
                </b-link>
                <b-popover
                  :target="'product-' + item.product_id"
                  placement="right"
                  variant="primary"
                  triggers="hover"
                >
                  <product-tooltip :product="item" />
                </b-popover>
              </td>
              <td>{{ item.unit_code }}</td>
              <td class="text-right" :data-order="productPriceTLPure(item)">
                {{ productPrice(item) }}
              </td>
              <td class="text-right" :data-order="productPriceTLPure(item)">
                {{ productPriceTL(item) }}
              </td>
            </tr>
          </tbody>
        </table>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BDropdown,
  BPopover,
  BLink,
  BOverlay,
} from "bootstrap-vue";
import "@/assets/js/functions/datatables.settings";
import $ from "jquery";
import { mapGetters } from "vuex";
import {
  convertTL,
  priceFormat,
} from "@/assets/js/functions/currency-functions";
import ProductTooltip from "../../Order/Product/Tooltip.vue";
import { GET_COMPANY_PRICE_LIST } from "@/store/services/priceList-service";
import moment from "moment";
/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BDropdown,
    BPopover,
    BLink,
    BOverlay,
    ProductTooltip,
  },
  props: {
    companyData: { required: true },
  },
  data() {
    return {
      tableData: [],
      table: null,
      tableReady: false,
    };
  },
  computed: {
    ...mapGetters(["getLocale", "currency"]),
  },
  created() {
    this.$store
      .dispatch(GET_COMPANY_PRICE_LIST, this.$route.params.id)
      .then((response) => {
        this.tableData = response.products;
        this.$nextTick(() => {
          this.createTable();
        });
      });
  },
  methods: {
    createTable() {
      this.tableReady = false;
      this.table = $("#companyPrices").DataTable({
        //columnDefs: [{ orderable: false, targets: [4] }],
        scrollX: true,
        scrollY: "1000px",
        scrollCollapse: true,
        buttons: [
          {
            extend: "colvis",
            text: this.$t("dataTable.show_hide"),
          },
          {
            extend: "pdf",
            title: `${this.companyData.company_trade_name}
            ${this.$t("company.price_list")}\n
            ${this.$t("company.price_list_create_date")}
            ${moment().format("DD.MM.YYYY HH:mm")}`,
            filename: `${this.companyData.company_trade_name}_
            ${this.$t("company.price_list")} 
            ${moment().format("DD_MM_YYYY")}`,
          },
          {
            extend: "excel",
            title: "Bayi Fiyat Listesi",
            filename: "Bayi Fiyat Listesi",
          },
        ],
        language: {
          url:
            this.getLocale === "tr-TR"
              ? "//cdn.datatables.net/plug-ins/1.11.1/i18n/tr.json"
              : "//cdn.datatables.net/plug-ins/1.11.1/i18n/en-gb.json",
        },
      });
      this.tableReady = true;
    },
    productPrice(item) {
      return priceFormat(
        item.unit_price_tra,
        this.getLocale,
        item.currency_code
      );
    },
    productPriceTLPure(item) {
      return convertTL(item.unit_price_tra, item.currency_code, this.currency);
    },
    productPriceTL(item) {
      let try_val = this.productPriceTLPure(item);
      return priceFormat(try_val, this.getLocale, "TRY");
    },
    reDraw() {
      Promise.all([this.table.destroy()]).then(this.createTable);
    },
  },
  watch: {
    getLocale() {
      this.reDraw();
    },
  },
};
</script>
